import {
  ref,
  onMounted,
  getCurrentInstance,
  reactive,
  watch,
  computed
} from 'vue'
import {
  senderDataUseCase
} from '@/domain/usecase'
import {
  useStore
} from 'vuex'
import useVuelidate from '@vuelidate/core'
import {
  required,
  helpers
} from '@vuelidate/validators'
import moment from 'moment'
import route from '@/router/master/transactions/route'
import { isNull } from 'lodash'
import { checkPermission } from '@/utils/helpers/checkPermission'
import PermissionEnum from '@/utils/enum/permissions'

const modules = 'deliveryItem'

export default {
  name: 'FormSenderRetail',
  setup() {
    const store = useStore()
    const customerOption = ref([]) as any
    const companyAdressOption = ref([])
    const app = getCurrentInstance()
    const addressReceiverRef = ref()
    const paymentTypeId = ref()
    const scheduleOption = ref([])
    const isDooring = ref(false)
    const customerPageNumber = ref(1)
    const hasNextPage = ref(false)
    const {
      $toast,
      $confirm
    } = app!.appContext.config.globalProperties
    const dataForm = reactive({
      customerId: computed(() => store.state[modules].customerId),
      sendingAddressId: computed(() => store.state[modules].adressCompanyId),
      addressSender: computed({
        get: () => store.state[modules].dataSender.addressSender,
        set: (val) => {
          store.dispatch(`${modules}/setDataSender`, {
            namaPengirim: store.state[modules].dataSender.nameSender,
            alamatPengirim: val,
            telpPengirim: store.state[modules].dataSender.telpSender,
            pickupScheduleId: store.state[modules].dataSender.pickupScheduleId
          })
        }
      }),
      pickupScheduleId: computed({
        get: () => store.state[modules].dataSender.pickupScheduleId,
        set: (val) => {
          // console.log('pickup', val)
          store.dispatch(`${modules}/setDataSender`, {
            namaPengirim: store.state[modules].dataSender.nameSender,
            alamatPengirim: store.state[modules].dataSender.addressSender,
            telpPengirim: store.state[modules].dataSender.telpSender,
            pickupScheduleId: val,
            usernamePengirim: store.state[modules].dataSender.usernameSender
          })
          // console.log('state', store.state[modules].dataSender)
        }
      }),
      telpSender: computed(() => store.state[modules].dataSender.telpSender),
      nameSender: computed(() => store.state[modules].dataSender.nameSender),
      nameReceiver: computed({
        get: () => store.state[modules].dataReceiver.nameReceiver,
        set: (val) => {
          store.dispatch(`${modules}/setDataReceiver`, {
            namaPenerima: val,
            alamatPenerima: store.state[modules].dataReceiver.addressReceiver,
            telpPenerima: store.state[modules].dataReceiver.telpReceiver,
            notesPenerima: store.state[modules].dataReceiver.notesReceiver
          })
        }
      }),
      telpReceiver: computed({
        get: () => store.state[modules].dataReceiver.telpReceiver,
        set: (val) => {
          store.dispatch(`${modules}/setDataReceiver`, {
            namaPenerima: store.state[modules].dataReceiver.nameReceiver,
            alamatPenerima: store.state[modules].dataReceiver.addressReceiver,
            telpPenerima: val,
            notesPenerima: store.state[modules].dataReceiver.notesReceiver
          })
        }
      }),
      addressReceiver: computed({
        get: () => store.state[modules].dataReceiver.addressReceiver,
        set: (val) => {
          store.dispatch(`${modules}/setDataReceiver`, {
            namaPenerima: store.state[modules].dataReceiver.nameReceiver,
            alamatPenerima: val,
            telpPenerima: store.state[modules].dataReceiver.telpReceiver,
            notesPenerima: store.state[modules].dataReceiver.notesReceiver
          })
        }
      }),
      notesReceiver: computed({
        get: () => store.state[modules].dataReceiver.notesReceiver,
        set: (val) => {
          store.dispatch(`${modules}/setDataReceiver`, {
            namaPenerima: store.state[modules].dataReceiver.nameReceiver,
            alamatPenerima: store.state[modules].dataReceiver.addressReceiver,
            telpPenerima: store.state[modules].dataReceiver.telpReceiver,
            notesPenerima: val
          })
        }
      })
    }) as any
    const submitted = ref(false)

    const rules = {
      customerId: {
        required: helpers.withMessage('Customer Pengirim harus diisi', required)
      },
      notesReceiver: {
        required: helpers.withMessage('Keterangan Alamat harus diisi', required)
      },
      nameReceiver: {
        required: helpers.withMessage('Penerima harus diisi', required)
      },
      telpReceiver: {
        required: helpers.withMessage('No. Telp Penerima harus diisi', required)
      },
      addressReceiver: {
        required: helpers.withMessage('Alamat Penerima harus diisi', required)
      },
    }
    const v$ = useVuelidate(rules, dataForm)

    const getOptionCustomer = async () => {
      const filter = checkPermission(PermissionEnum.NoFilPelRe) ? `and KotaId eq ${store.getters['appActiveUser/getAgent'].kotaId}` : ''
      const response = await senderDataUseCase.getSelectDataCustomer(filter)
      customerOption.value = response.result.map((x: any) => ({
        label: x.Nama,
        value: x.Id
      }))
    }

    const getOptionCustomerV2 = async (search: any = '') => {
      const filter = checkPermission(PermissionEnum.NoFilPelRe) ? '' : ''
      console.log('filter', filter)
      const response = await senderDataUseCase.getSelectDataCustomerV2(filter, customerPageNumber.value, search)
      // hasNextPage.value = response.result.HasNextPage
      // customerPageNumber.value = hasNextPage.value ? customerPageNumber.value + 1 : customerPageNumber.value
      const customerOptionTemp = response.result.Data.map((x: any) => ({
        label: x.Fullname,
        value: x.Id
      }))
      console.log('customerOptionTemp', customerOptionTemp)
      customerOptionTemp.forEach((data: any) => {
        customerOption.value.push(data)
      })
      console.log('customerOption', customerOption.value)
      // if (hasNextPage.value) {
      //   getOptionCustomerV2()
      // }
    }

    const getSpecificCustomer = async (idCompany: any) => {
      store.dispatch('showLoading')
      const response = await senderDataUseCase.getSpecificCustomer(idCompany)
      const data = response.result
      store.dispatch(`${modules}/setDataSender`, {
        namaPengirim: data.Nama,
        alamatPengirim: data.Alamat,
        telpPengirim: data.Telepon
      })
      dataForm.addressSender = data.Alamat
      dataForm.nameSender = data.Nama
      dataForm.telpSender = data.Telepon
      store.dispatch('hideLoading')
    }

    const getSpecificCustomerV2 = async (idCompany: any) => {
      store.dispatch('showLoading')
      if (!isNull(idCompany)) {
        const response = await senderDataUseCase.getSpecificCustomerV2(idCompany)
        const data = response.result
        console.log('data', data)
        store.dispatch(`${modules}/setDataSender`, {
          namaPengirim: data.Fullname,
          alamatPengirim: data?.Retail?.PrimaryAddress?.Alamat1 ?? '-',
          telpPengirim: data.PhoneNumber,
          usernamePengirim: data.Username
        })
        dataForm.addressSender = data?.Retail?.PrimaryAddress?.Alamat1 ?? '-'
        dataForm.nameSender = data.Fullname
        dataForm.telpSender = data.PhoneNumber
        dataForm.usernameSender = data.Username
      } else {
        store.dispatch(`${modules}/setDataSender`, {
          namaPengirim: null,
          alamatPengirim: null,
          telpPengirim: null,
          usernamePengirim: null
        })
        dataForm.addressSender = null
        dataForm.nameSender = null
        dataForm.telpSender = null
        dataForm.usernameSender = null
      }
      store.dispatch('hideLoading')
    }

    const updateValueAction = (val: any) => {
      store.dispatch(`${modules}/setCustomerId`, val)
    }
    const searchOptions = (val: any) => {
      console.log('val', val)
      if (val.length > 2) {
        getOptionCustomerV2(`&fullname=${val}`)
      }
    }

    watch(() => dataForm.customerId, (newValue, oldValue) => {
      console.log('customer change', newValue)
      if (newValue !== oldValue) {
        getSpecificCustomerV2(newValue)
      }
    })

    onMounted(() => {
      // eslint-disable-next-line no-use-before-define
      getOptionSchedule()
      // eslint-disable-next-line no-use-before-define
      getDefaultPaymentType()
      getOptionCustomerV2()
      // eslint-disable-next-line no-use-before-define
      checkRoute()

      if (dataForm.customerId) {
        getSpecificCustomerV2(dataForm.customerId)
      }
    })

    const prosesSubmit = async () => {
      store.dispatch('showLoading')
      const data = {
        AgenAsalId: store.getters['appActiveUser/getAgent'].id,
        AgenTujuanId: store.state[modules].toAgentId,
        NamaPengirim: dataForm.nameSender,
        AlamatPengirim: dataForm.addressSender,
        HpPengirim: dataForm.telpSender,
        NamaPenerima: dataForm.nameReceiver,
        AlamatPenerima: dataForm.addressReceiver,
        HpPenerima: dataForm.telpReceiver,
        PelangganId: dataForm.customerId,
        Username: dataForm.usernameSender,
        TipePengiriman: store.state[modules].typeDelivery,
        TransJadwalHdId: store.state[modules].scheduleId,
        KeteranganPenerima: dataForm.notesReceiver,
        JenisPembayaranId: paymentTypeId.value,
        TransJadwalKurirId: dataForm.pickupScheduleId === 0 ? null : dataForm.pickupScheduleId
      }
      const result = await senderDataUseCase.newSubmitData(data, store.state[modules].dataAfterPost !== null ? store.state[modules].dataAfterPost.Id : null)
      if (!result.error) {
        store.dispatch('hideLoading')
        // await store.dispatch(`${modules}/setDataAfterPost`, result.result)
        store.dispatch(`${modules}/setCurrentIndex`, 2)
      } else {
        store.dispatch('hideLoading')
        $toast.add({
          severity: 'error',
          detail: result.message,
          group: 'bc',
          life: 3000
        })
      }
    }

    const getDefaultPaymentType = async () => {
      const response = await senderDataUseCase.getDefaultPaymentType()
      const data = response.result
      // console.log(data[0].Id)
      paymentTypeId.value = data[0].Id
    }

    const submitData = (isFormValid: boolean) => {
      submitted.value = true
      if (!isFormValid) {
        return
      }
      prosesSubmit()
    }

    const submitBack = () => {
      store.dispatch(`${modules}/setCurrentIndex`, 0)
    }

    const setResultAddressReceiver = (result: any) => {
      dataForm.addressReceiver = result
    }

    const getOptionSchedule = async () => {
      moment.locale('id')
      const response = await senderDataUseCase.getOptionSchedule()
      // console.log('res', response)
      scheduleOption.value = response.result.map((x: any) => ({
        label: `${moment(x.WaktuMulaiPenjemputan).format('D MMMM YYYY')} ${moment(x.WaktuMulaiPenjemputan).format('HH:mm')} WIB - ${moment(x.WaktuAkhirPenjemputan).format('HH:mm')} WIB`,
        value: x.Id
      }))
      // console.log('val', scheduleOption.value)
    }

    const checkRoute = async () => {
      const currType = store.state[modules].typeDelivery
      if (currType === 'DA' || currType === 'DD') {
        isDooring.value = true
      }
    }

    const test = () => {
      console.log('aaaa')
    }
    // watch([dataForm.addressSender], ([newValues], [prevValues]) => {
    //   console.log(newValues, prevValues)
    // })
    return {
      customerOption,
      dataForm,
      getOptionCustomer,
      updateValueAction,
      setResultAddressReceiver,
      addressReceiverRef,
      companyAdressOption,
      store,
      submitData,
      submitBack,
      v$,
      rules,
      submitted,
      app,
      scheduleOption,
      isDooring,
      test,
      hasNextPage,
      searchOptions
    }
  }
}
